@import "../../assets/styles/index.scss";

.products-container {
  margin-bottom: 2rem;

  background-color: #ebecf0;
  color: $blue;

  .product {
    margin-bottom: 1rem;
    strong {
      font-weight: bold;
    }
  }
  .date-effet {
    display: inline-block;
    padding: 5px 10px;
    background-color: $blue;
    color: white;
  }
}

.subscribers {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $blue;
  margin-bottom: 1rem;

  thead {
    background-color: $blue;
    color: white;
  }
  td {
    padding: 1rem;
    border-bottom: 1px solid $blue;
  }
}

.garanties {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  li {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;

    i {
      font-size: 2.5rem;
    }

    .garantie-file {
      text-align: center;
      max-width: 100px;
    }
  }
  a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: #0d5b8e;
  }
  a:hover {
    text-decoration: underline;
  }
}

.url-signature {
  margin-bottom: 4rem;
}

ul {
  list-style: none;
  padding-left: 0;
}
