@font-face {
  font-family: "verdana";
  src: local("verdana"), url(../fonts/verdana/verdana.ttf) format("truetype");
}

@font-face {
  font-family: "verdana-bold";
  src: local("verdana-bold"),
    url(../fonts/verdana/verdanab.ttf) format("truetype");
}

@font-face {
  font-family: "century";
  src: local("century"),
    url(../fonts/gothic-century/GOTHIC.TTF) format("truetype");
}

@font-face {
  font-family: "century-bold";
  src: local("century-bold"),
    url(../fonts/gothic-century/GOTHICB.TTF) format("truetype");
}

@font-face {
  font-family: "brush-script";
  src: local("brush-script"),
    url(../fonts/brush-script-mt/BRUSHSCI.ttf) format("truetype");
}

@font-face {
  font-family: "asar";
  src: local("asar"), url(../fonts/asar/Asar_Regular.ttf) format("truetype");
}

/* Declaring colors*/
$blue: #2f4c87;
$blue1: #2d5699;
$blue2: #6691ca;
$blue3: #a1b7de;
$pink: #cc287b;
$teal: #149db1;
$green: #bbd148;
$dark: #4a4a4a;
$dark1: #00020f;
$green_santiane: #6fb632;
$orange_santiane: #f39200;

$magenta: #cc287b;
$turquoise: #149db1;
$verty: #bbd148;

body {
  margin: 0;
  font-family: "verdana", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "century-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  color: $dark1;
}

h2 {
  font-family: "century-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: $dark1;
}

h3 {
  font-family: "century-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: $dark1;
}

p {
  font-family: "verdana";
  font-style: normal;
  font-size: 18px;
  color: $dark;
  line-height: 27px;
}

ul {
  list-style: none;
  padding-left: inherit;
}

li {
  color: $dark;
}

.text-center {
  text-align: center !important;
}

.century {
  font-family: "century";
}

.asar {
  font-family: "asar";
  font-weight: bold;
}

.century-bold {
  font-family: "century-bold";
}

.century-pink {
  font-family: "century-bold";
  color: $pink;
  font-size: 28px;
}

.p-bold {
  font-family: "verdana-bold";
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
}

.p-white {
  color: white !important;
  font-size: 15px;
}

.p-dark {
  color: $dark1;
  font-weight: 500;
  font-size: 22px;
}

.h1-white {
  color: white !important;
  margin: 0;
}

.h2-white {
  color: white !important;
  margin: 0;
}

.h3-white {
  color: white !important;
  margin: 0;
}

.text-white {
  font-family: "century";
  font-size: 16px;
  color: white;
}

.text-magenta {
  color: $magenta;
}

.text-turquoise {
  color: $turquoise;
}

.text-verty {
  color: $verty;
}

.text-blue-1 {
  color: $blue1;
}

.text-pink {
  color: $pink;
}

.text-blue-2 {
  color: $blue2;
}

.text-green-santiane {
  color: $green_santiane;
}

.text-orange-santiane {
  color: $orange_santiane;
}

.text-bold {
  font-weight: bold !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.m-0 {
  margin: 0 !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.small {
  opacity: 0.6;
  font-family: "century";
}

.bg-badge {
  padding: 0px 10px 5px 10px;
  border-radius: 5px;
}

.bg-pink {
  background-image: url("../doodles/doodle\ rectangle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px 10px 5px 10px;
  color: white;
  white-space: nowrap !important;
}

.bg-doodle-circle {
  background-image: url("../../assets/doodles/doodle_circle_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 32px 10px 32px 10px;
}

.bg-doodle-circle-pink {
  background-image: url("../../assets/doodles/doodle_cirle_pink.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 32px 10px 32px 10px;
}

.bg-doodle-green {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background: url("../../assets/doodles/doodle_green.svg");
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    top: -40px;
    left: 40px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.bg-doodle-pink {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background: url("../../assets/doodles/doodle.svg");
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    top: -40px;
    left: 40px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-highlight {
  background-image: url("../../assets/doodles/doodle\ highlight.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0px 0px 80px 0px;
}

.bg-highlight-1 {
  background-image: url("../../assets/doodles/doodle\ highlight.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0px 0px 50px 0px;
}

.bg-highlight-2 {
  background-image: url("../../assets/doodles/doodle\ highlight\ 2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0px 0px 30px 0px;
}

.bg-highlight-3 {
  background-image: url("../../assets/doodles/doodle\ circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0px 60px 0px;
}

.half-background-list {
  position: relative;
}

.half-background-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: $blue;
  z-index: -1;
}

.bg-blue-list {
  background-color: $blue;
  padding-top: 64px;
  padding-bottom: 64px;
  border-radius: 0px 0px 10px 10px;
}

.bg-blue-list-emprunteur {
  background-color: $blue;
  padding-top: 0px;
  padding-bottom: 170px;
  border-radius: 0px 0px 10px 10px;
}

.bg-blue-list-join {
  background-color: $blue;
  padding-top: 0px;
  padding-bottom: 40px;
  border-radius: 0px 0px 10px 10px;
}

.bg-blue {
  background-color: $blue;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0.5em;
}

.hidden-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.hidden-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.MuiTypography-body1 {
  font-family: "century" !important;
}

@media (max-width: 900px) {
  h1 {
    font-family: "century-bold";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: $dark1;
  }

  h2 {
    font-family: "century-bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: $dark1;
  }

  h3 {
    font-family: "century-bold";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: $dark1;
  }

  p {
    font-family: "verdana";
    font-style: normal;
    font-size: 16px;
    color: $dark;
    line-height: 27px;
  }

  .p-dark {
    font-size: 18px;
  }

  .p-bold {
    font-family: "verdana-bold";
    font-style: normal;
    font-size: 16px;
    line-height: 27px;
  }

  .bg-highlight {
    padding: 0px 0px 40px 0px;
  }

  .bg-highlight-1 {
    padding: 0px 0px 30px 0px;
  }

  .bg-doodle-green {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      background: url("../../assets/doodles/doodle_green.svg");
      background-repeat: no-repeat;
      background-position: top right;
      position: absolute;
      background-size: 30px;
      top: -10px;
      left: 15px;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}
