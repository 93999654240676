@import "../../assets/styles/index.scss";

.btn-contact {
    color: white !important;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "century" !important;
    background-color: $blue2 !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    padding: 15px 15px 15px 15px !important;
}

.btn-contact-outlined {
    color: #999 !important;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "century" !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    padding: 15px 15px 15px 15px !important;
    border: 2px solid #bebebe !important;
}

.btn-contact-outlined:hover {
    color: #999 !important;
    border: 2px solid #999 !important;
}

.btn-decouvrir-pink {
    color: white !important;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $pink !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    font-size: 18px !important;
}

.MuiInputLabel-root {
    font-family: "verdana" !important;
    color: #999 !important;

}

.etre-rappelle svg {
    transform: rotate(-25deg);
}

.contact-input input {
    padding-bottom: 12px !important;
}

.contact {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        background: url(../../assets/images/ellipse.svg);
        background-repeat: no-repeat;
        background-position: bottom right;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}


@media (max-width: 900px) {
    #contact .MuiInputLabel-root {
        color: white !important;
    }

    #contact .MuiInputBase-root::before {
        border-bottom: 2px solid white !important;
    }

    #contact .MuiInputBase-root::after {
        border-bottom: 2px solid white !important;
    }

    #contact .MuiInput-input {
        color: white !important;
    }

    .btn-contact-outlined {
        color: white !important;
        border: 2px solid white !important;
    }

    .btn-contact-outlined:hover {
        color: white !important;
        border: 2px solid white !important;
    }
}