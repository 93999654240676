@import "../../assets/styles/index.scss";

.header {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.header-item {
    color: $dark;
    text-transform: none;
    font-weight: normal;
    cursor: pointer;
    font-family: "century";
}

.header-item:hover {
    color: $blue;
    font-family: "century-bold";
}

.header-item-active {
    color: $blue;
    font-family: "century-bold";
    line-height: 2.5;
    border-bottom: 2px solid $blue;
}

.header-item-active-mobile {
    color: $blue;
    font-family: "century-bold";
}

.nous-contacter {
    color: white;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $blue !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    font-size: 17px !important;
}

#menu-appbar .MuiMenu-list {
    padding: 20px 20px 20px 20px !important;
}