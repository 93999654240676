@import "../../assets/styles/index.scss";

.bandeau-cookies {
  -webkit-box-shadow: 0 4px 5px 6px #ccc;
  -moz-box-shadow: 0 4px 5px 6px #ccc;
  box-shadow: 0 4px 5px 6px #ccc;
}

.btn-accept-cookies {
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  background-color: white !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 8px 24px 8px 24px !important;
  font-size: 18px !important;
}

.url-cookies {
  color: white !important;
  text-decoration: underline !important;
  font-weight: bold !important;
  cursor: pointer !important;
}
