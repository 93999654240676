@import "../../assets/styles/index.scss";

.welcome {
  background: #99a3ff;
  padding: 40px;

  img {
    margin: auto;
    display: block;
  }

  h3 {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }
  h5,
  p {
    color: #fff;
    font-weight: 400;
    text-align: center;
  }

  .prev {
    text-align: center;
    color: #fff;
  }
}

.contact-bienvenue {
  padding: 40px;
  padding-bottom: 25px;
  p {
    color: $dark;
    font-weight: 400;
    text-align: center;
    font-family: "century";
  }
}
